// Generate dynamic routes
// permissionCode to check if it contains a specific permission code
/* Layout */
import Layout from "@/layout";

export const asyncRoutes = [
  {
    path: "/system",
    component: Layout,
    name: "system",
    permissionCode: "system_menu",
    meta: { title: "System", icon: "el-icon-s-tools" },
    children: [
      {
        path: "role",
        name: "system_role",
        permissionCode: "role_manage", // Contains permission code
        component: () => import("@/views/system/role"),
        meta: { title: "Role", icon: "el-icon-s-opportunity" },
      },
      {
        path: "employee",
        name: "system_employee",
        permissionCode: "employee_manage",
        component: () => import("@/views/system/employee"),
        meta: { title: "Employee", icon: "el-icon-user-solid" },
      },
      {
        path: "message",
        name: "update_message",
        permissionCode: "update_message",
        component: () => import("@/views/system/update"),
        meta: { title: "UpdateLog", icon: "el-icon-user-solid" },
      },
    ],
  },
  {
    path: "/order",
    component: Layout,
    alwaysShow: true,
    name: "order",
    permissionCode: "order_menu",
    meta: { title: "Underwrite", icon: "el-icon-document-copy" },
    children: [
      {
        path: "index",
        name: "system_order",
        permissionCode: "order_manage",
        component: () => import("@/views/order/index"),
        meta: { title: "Underwrite", icon: "el-icon-s-order" },
      },
      {
        path: "renew",
        name: "renew_order",
        permissionCode: "renew_manage",
        component: () => import("@/views/order/renew"),
        meta: { title: "Renew", icon: "el-icon-s-order" },
      },
    ],
  },
  {
    path: "/approval",
    component: Layout,
    alwaysShow: true,
    name: "approval",
    permissionCode: "approval_menu",
    meta: { title: "Approval", icon: "el-icon-document-copy" },
    children: [
      {
        path: "index",
        name: "system_approval",
        permissionCode: "approval_list",
        component: () => import("@/views/approval/index"),
        meta: { title: "Approval", icon: "el-icon-s-order" },
      },
    ],
  },
  {
    path: "/customer",
    component: Layout,
    name: "customer",
    permissionCode: "customer_menu",
    meta: { title: "Customer", icon: "el-icon-s-check" },
    children: [
      {
        path: "index",
        name: "customer_index",
        permissionCode: "customer_manage",
        component: () => import("@/views/customer/index"),
        meta: { title: "Customer", icon: "el-icon-s-custom" },
      },
      {
        path: "ship",
        name: "customer_ship",
        permissionCode: "ship_manage",
        component: () => import("@/views/customer/ship"),
        meta: { title: "Vessel", icon: "el-icon-ship" },
      },
      {
        path: "email",
        name: "customer_email",
        permissionCode: "email_manage",
        component: () => import("@/views/customer/email"),
        meta: { title: "Email", icon: "el-icon-message" },
      },
      // {
      //   path: "todo",
      //   name: "customer_todo",
      //   // permissionCode: 'employee_manage',
      //   component: () => import("@/views/customer/todo"),
      //   meta: { title: "Customer To-Do", icon: "el-icon-s-order" },
      // },
    ],
  },
  {
    path: "/business",
    component: Layout,
    alwaysShow: true,
    name: "business",
    permissionCode: "insurance_menu",
    meta: { title: "Insurance", icon: "el-icon-office-building" },
    children: [
      {
        path: "index",
        name: "business_index",
        permissionCode: "insurance_my",
        component: () => import("@/views/business/index"),
        meta: { title: "Insurance", icon: "el-icon-school" },
      },
      // {
      //   path: "todo",
      //   name: "business_todo",
      //   // permissionCode: 'employee_manage',
      //   component: () => import("@/views/business/todo"),
      //   meta: { title: "Insurance Company To-Do", icon: "el-icon-s-order" },
      // },
    ],
  },
  {
    path: "/account",
    component: Layout,
    alwaysShow: true,
    name: "account",
    permissionCode: "account_menu",
    meta: { title: "Account", icon: "el-icon-s-order" },
    children: [
      {
        path: "index",
        name: "account_index",
        permissionCode: "account_flow_list",
        component: () => import("@/views/account/index"),
        meta: { title: "Ledger", icon: "el-icon-s-finance" },
      },
      {
        path: "receipt",
        name: "receipt_index",
        permissionCode: "account_receipt_list",
        component: () => import("@/views/account/receipt"),
        meta: { title: "Receipt", icon: "el-icon-s-order" },
      },
    ],
  },
  {
    path: "/claims",
    component: Layout,
    alwaysShow: true,
    name: "claims",
    permissionCode: "claims_menu",
    meta: { title: "Claims", icon: "el-icon-s-claim" },
    children: [
      {
        path: "index",
        name: "claims_index",
        permissionCode: "claims_list",
        component: () => import("@/views/claims/index"),
        meta: { title: "Claims", icon: "el-icon-s-claim" },
      },
      {
        path: "todo",
        name: "todo_index",
        permissionCode: "todo_list",
        component: () => import("@/views/claims/todos"),
        meta: { title: "Todos", icon: "el-icon-bell" },
      },
    ],
  },
  {
    path: "/debit",
    component: Layout,
    alwaysShow: true,
    name: "debit",
    permissionCode: "debit_menu",
    meta: { title: "Billing", icon: "debit" },
    children: [
      {
        path: "index",
        name: "debit_index",
        permissionCode: "debit_list",
        component: () => import("@/views/debit/index"),
        meta: { title: "Debits", icon: "debit" },
      },
    ],
  },

  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];
